'use client'

import Image from 'next/image'
import Button from "@modules/common/components/button"
import Link from 'next/link'
import { useStore } from '@lib/context/store-context'
import { cdnLoader } from '@lib/loaders/cdn-loader'

const HowItWorks = () => {
    const { checkIfPostalCodeCheckIsNeeded } = useStore()

    const onCheckPostcodeClick = () => {
        if (checkIfPostalCodeCheckIsNeeded()) {
            null
        }
    }

    return (
        <>
        <section className="py-5 lg:py-10 bg-gray-100 pb-10 md:pb-24 lg:pb-36">
            <div className="container max-w-screen-lg mx-auto px-4 flex flex-col items-center">
                <h2 className="font-bold text-center py-2 lg:py-7 mb-8 text-3xl lg:text-4xl mx-auto">How it works</h2>
                <div className="flex flex-col gap-10 md:gap-20 lg:gap-28">
                    <div className="flex flex-row gap-4 lg:gap-6 lg:py-12 px-2 lg:px-12 bg-white rounded-xl">
                        <div className="flex justify-end items-center relative -my-36">
                            <div className="w-40 h-40 md:w-60 md:h-60 -ml-4 lg:ml-0 lg:w-80 lg:h-80 rounded-full overflow-hidden">
                                <Image
                                    loader={cdnLoader}
                                    src="/img/home/how-it-works-schedule.png"
                                    className="w-full h-full object-cover"
                                    alt="Woman sat using her phone smiling"
                                    layout="responsive"
                                    sizes="100vw"
                                    width="200"
                                    height="200"
                                />
                            </div>
                        </div>
                        <div className="flex grow flex-col py-5 px-2 lg:px-10">
                            <h3 className="py-2 lg:text-2xl">Set up your weekly shop online</h3>
                            <p className="pb-5">We deliver to different postcodes on different days each week. Choose items to come regularly or as a one-off so you always have what you need.</p>
                            <p>
                                <Link href="/products"><span className="underline">Get started</span> →</Link>
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row gap-3 lg:gap-6 lg:py-12 px-2 lg:px-12 bg-white rounded-xl">
                        <div className="flex grow flex-col py-5 px-2 lg:px-10">
                            <h3 className="py-2 lg:text-2xl">No commitment, flexible subscription</h3>
                            <p className="pb-5">Change, pause or cancel your shop until two days before, and we&apos;ll send you a reminder to check your order each week.</p>
                            <p>
                                <Link href="https://intercom.help/weeklyshop/en/articles/9585617-flexible-subscription"><span className="underline">Learn more</span> →</Link>
                            </p>
                        </div>
                        <div className="flex justify-end items-center relative -my-36">
                            <div className="w-40 h-40 md:w-60 md:h-60 lg:w-80 lg:h-80 -mr-4 lg:mr-0 rounded-full overflow-hidden">
                                <Image
                                    loader={cdnLoader}
                                    src="/img/home/how-it-works-flexible.png"
                                    className="w-full h-full object-cover"
                                    alt="Website shown a phone screen held in a hand"
                                    layout="responsive"
                                    sizes="100vw"
                                    width="200"
                                    height="200"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row gap-2 lg:gap-6 lg:py-12 px-2 lg:px-12 bg-white rounded-xl">
                        <div className="flex justify-end items-center relative -my-36">
                            <div className="w-40 h-40 md:w-60 md:h-60 -ml-4 lg:ml-0 lg:w-80 lg:h-80 rounded-full overflow-hidden">
                                <Image
                                    loader={cdnLoader}
                                    src="/img/home/how-it-works-containers.png"
                                    className="w-full h-full object-cover"
                                    alt="Various re-usable containers containing a variety of goods"
                                    layout="responsive"
                                    sizes="100vw"
                                    width="200"
                                    height="200"
                                />
                            </div>
                        </div>
                        <div className="flex grow flex-col py-5 px-2 lg:px-10">
                            <h3 className="py-2 lg:text-2xl">You don&apos;t need your own containers</h3>
                            <p className="pb-5">Keep and use our containers until they are empty. No need to transfer or decant anything.</p>
                            <p>
                                <Link href="https://intercom.help/weeklyshop/en/articles/9585833-reusable-containers"><span className="underline">Learn more</span> →</Link>
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row gap-2 lg:gap-6 lg:py-12 px-2 lg:px-12 bg-white rounded-xl">
                        <div className="flex grow flex-col py-5 px-2 lg:px-10">
                            <h3 className="py-2 lg:text-2xl">We take care of collection and cleaning</h3>
                            <p className="pb-5">When we deliver, we also collect empties to be cleaned to the highest standards.</p>
                            <p>
                                <Link href="https://intercom.help/weeklyshop/en/articles/9584999-delivery-and-collection"><span className="underline">Learn more</span> →</Link>
                            </p>
                        </div>
                        <div className="flex justify-end items-center relative -my-36">
                            <div className="rounded-full flex-none w-40 h-40 bg-gray-100 absolute left-1/2 -translate-x-1/2 -z-10"></div>
                            <div className="w-40 h-40 md:w-60 md:h-60 -mr-4 lg:mr-0 lg:w-80 lg:h-80 rounded-full overflow-hidden">
                                <Image
                                    loader={cdnLoader}
                                    src="/img/home/how-it-works-cleaning.png"
                                    className="w-full h-full object-cover"
                                    alt="Clean bottles in a washing rack"
                                    layout="responsive"
                                    sizes="100vw"
                                    width="200"
                                    height="200"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        More questions? <Link href="https://intercom.help/weeklyshop/"><span className="underline">Visit our FAQs</span> →</Link>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default HowItWorks