'use client'

import Image from 'next/image'
import Button from "@modules/common/components/button"
import Link from 'next/link'

const SustainableShopping = () => {
    return (
        <>
            <section className="py-10 lg:py-16">
                <div className="container max-w-screen-lg mx-auto px-4">
                    <h2 className="text-3xl lg:text-4xl font-bold text-center mb-4 mx-auto">Making sustainable shopping easy</h2>
                    <div className="grid md:grid-cols-3 gap-10 pt-10">
                        {/* Box 1 */}
                        <div className="p-8 flex flex-col gap-y-2 bg-weekly-green bg-opacity-20 relative rounded-lg">
                            <h3 className="pb-3 text-2xl text-lg font-semibold self-start">Convenient</h3>
                            <p>Wide range delivered to your door. Containers and collection are included.</p>
                        </div>
                        {/* Box 2 */}
                        <div className="p-8 flex flex-col gap-y-2 bg-weekly-green bg-opacity-20 relative rounded-lg">
                            <h3 className="pb-3 text-2xl text-lg font-semibold self-start">Affordable</h3>
                            <p>Reusing packaging is cheaper than using it once.</p>
                        </div>
                        {/* Box 3 */}
                        <div className="p-8 flex flex-col gap-y-2 bg-weekly-green bg-opacity-20 relative rounded-lg">
                            <h3 className="pb-3 text-2xl text-lg font-semibold self-start">Impactful</h3>
                            <p>Help make a big change without having to make big changes.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SustainableShopping