'use client'

import ReviewsIoBadge from '@modules/home/components/reviews-io-badge'

const Testimonials = () => {
    return (
        <>
            <div className="w-full flex justify-center bg-gray-100">
                <div className="flex justify-center items-center max-w-screen-xl w-full">
                    <div className="flex flex-col lg:flex-row items-center py-5">
                        <div className="flex-grow min-w-0">
                            <ReviewsIoBadge/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Testimonials
