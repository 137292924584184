'use client'

import { useStore } from '@lib/context/store-context'
import { bgUrlVar } from '@lib/util/cdn'

const Explore = () => {
    const { checkIfPostalCodeCheckIsNeeded } = useStore()

    const onCheckPostcodeClick = () => {
        if (checkIfPostalCodeCheckIsNeeded()) {
            null
        }
    }

    return (
        <>
            <section className="py-10 pb-28">
                <div className="container max-w-[900px] mx-auto px-4 flex-col items-center">
                    <h2 className="text-4xl font-bold text-center py-12 mx-auto">Explore zero-waste shopping</h2>
                    <div className="grid xsmall:grid-cols-2 md:grid-cols-3 gap-6 justify-items-center">
                        <a
                            style={bgUrlVar('/img/explore-fruit.jpg', 1600)}
                            className="aspect-square bg-[image:var(--bg-url)] relative h-64 bg-center bg-cover rounded-lg self-center" href="/fruit">
                            <p className="absolute bottom-0 backdrop-blur-sm bg-white/70 w-full p-3 font-medium">Fruit</p>
                        </a>
                        <a
                            style={bgUrlVar('/img/explore-vegetables.jpg', 1600)}
                            className="aspect-square bg-[image:var(--bg-url)] relative h-64 bg-center bg-cover rounded-lg" href="/vegetables">
                            <p className="absolute bottom-0 backdrop-blur-sm bg-white/70 w-full p-3 font-medium">Vegetables</p>
                        </a>
                        <a
                            style={bgUrlVar('/img/explore-dairy.jpg', 1600)}
                            className="aspect-square bg-[image:var(--bg-url)] relative h-64 bg-center bg-cover rounded-lg" href="/dairy-eggs">
                            <div className="absolute bottom-0 backdrop-blur-sm bg-white/70 w-full p-3 font-medium">Dairy</div>
                        </a>
                        <a
                            style={bgUrlVar('/img/explore-bread.jpg', 1600)}
                            className="aspect-square bg-[image:var(--bg-url)] relative h-64 bg-center bg-cover rounded-lg" href="/bakery">
                            <p className="absolute bottom-0 backdrop-blur-sm bg-white/70 w-full p-3 font-medium">Bakery</p>
                        </a>
                        {/*<a className="aspect-square bg-[url('/img/explore-pantry.jpg')] relative h-64 bg-center bg-cover rounded-lg" href="/pantry">
                        <p className="absolute bottom-0 bg-white/70 w-full p-3 font-medium">Pantry</p>
                    </a>*/}
                        <a
                            style={bgUrlVar('/img/explore-personal-care.jpg', 1600)}
                            className="aspect-square bg-[image:var(--bg-url)] relative h-64 bg-center bg-cover rounded-lg" href="/personal-care">
                            <p className="absolute bottom-0 backdrop-blur-sm bg-white/70 w-full p-3 font-medium">Personal Care</p>
                        </a>
                        <a
                            style={bgUrlVar('/img/explore-home-care.jpg', 1600)}
                            className="aspect-square bg-[image:var(--bg-url)] relative h-64 bg-center bg-cover rounded-lg" href="/home-care">
                            <p className="absolute bottom-0 backdrop-blur-sm bg-white/70 w-full p-3 font-medium">Home Care</p>
                        </a>
                        {/*<a className="bg-[url('/img/explore-plant-based.jpg')] relative h-64 bg-center bg-cover rounded-lg" href="/plant-based">
                        <p className="absolute bottom-0 bg-white/70 w-full p-3 font-medium">Plant Based</p>
                    </a>*/}
                    </div>
                </div>
            </section >
        </>
    )
}

export default Explore