export const getOptimizedImageUrl = (src: string, width: number, quality: number = 75): string => {
    return `https://cdn.weekly.shop/_next/image?url=${encodeURIComponent(src)}&w=${width}&q=${quality}`
}

type StyleWithBgUrl = React.CSSProperties & { '--bg-url'?: string }

export const bgUrlVar = (url: string, width: number, quality?:number): StyleWithBgUrl => {
    const imageUrl = `url(${getOptimizedImageUrl(url, width, quality)})`
    return { '--bg-url': imageUrl }
}
