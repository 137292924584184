'use client'

import React, { useEffect, useRef } from 'react';

const ReviewsIoBadge = () => {
    const initializedRef = useRef(false); // Initialize the ref with false

    useEffect(() => {
        // If the script hasn't been initialized yet, proceedA
        if (!initializedRef.current) {
            // Check if the script is already present in the DOM
            if (!document.querySelector('script[src="https://widget.reviews.io/badge-ribbon/dist.js"]')) {
                const script = document.createElement('script');
                script.src = "https://widget.reviews.io/badge-ribbon/dist.js";
                script.async = true;
                script.onload = () => {
                    // Initialize the reviewsBadgeRibbon and mark it as initialized
                    if (window.reviewsBadgeRibbon) {
                        window.reviewsBadgeRibbon("badge-ribbon", {
                            store: "www.weekly.shop",
                            size: "small",
                        });
                        initializedRef.current = true; // Set the ref to true
                    }
                };

                document.body.appendChild(script);
            } else {
                // Script is already present, initialize if not done yet
                if (window.reviewsBadgeRibbon) {
                    window.reviewsBadgeRibbon("badge-ribbon", {
                        store: "www.weekly.shop",
                        size: "small",
                    });
                    initializedRef.current = true; // Set the ref to true
                }
            }
        }
    }, []);

    return <div id="badge-ribbon"></div>
};

export default ReviewsIoBadge;