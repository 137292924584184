'use client'

import Image from 'next/image'
import Button from "@modules/common/components/button"
import Link from 'next/link'
import { bgUrlVar } from '@lib/util/cdn'
import { subscribe } from 'diagnostics_channel'
import { useCart } from 'medusa-react'
import { useSubscription } from '@lib/context/subscription-context'
import { useStore } from '@lib/context/store-context'
import { useEffect, useState } from 'react'
import { has } from 'lodash'

const Hero: React.FC = () => {
    const { cart } = useCart()
    const { subscription } = useSubscription()
    const { openPostcodeCheck, showPostcodeCheckButton } = useStore()

    return (
        <>
            <div className="w-full bg-[#E8E8E8] flex justify-center">
                <div
                    style={bgUrlVar('/img/hero.jpg', 1600)}
                    className="max-w-[1100px] bg-red w-full h-[420px] sm:h-[540px] lg:pt-8 lg:pb-16 lg:h-auto bg-bottom md:px-10 bg-[image:var(--bg-url)] bg-contain md:bg-75% lg:bg-contain md:bg-bottom-right bg-no-repeat relative mb-32 md:mb-0">
                    <div className="md:flex md:flex-row items-center">
                        <div className="basis-1/3 px-2">
                            <h1 className="text-4xl sm:text-5xl py-5">
                                Zero waste groceries, delivered.<br />
                            </h1>
                            <p className="max-w-md">Say goodbye to rubbish groceries, and hello to the new way to shop sustainably.</p>
                            <div className="absolute left-0 right-0 px-6 md:px-0 md:w-4/6 md:static -bottom-32 md:py-7 py-3 flex flex-col lg:flex-row gap-3">
                                <Button className="w-full justify-center" as="a" variant="primary" href="/products">Explore products</Button>
                                {showPostcodeCheckButton &&
                                    <Button className="w-full justify-center" as="a" variant="tertiary" href="/postcode-check" onClick={(e: React.MouseEvent) => { e.preventDefault(); openPostcodeCheck() }}>Check your postcode</Button>
                                }
                            </div>
                        </div>
                    </div>
                    <img alt="As seen in the grocer badge" src="/img/grocer.svg" className="h-20 -bottom-5 md:h-28 absolute rotate-6 md:rotate-0 md:top-auto  md:-bottom-20 right-2 md:right-10" />
                </div>
            </div>
        </>
    )
}

export default Hero
