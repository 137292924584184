import Spinner from '@modules/common/icons/spinner'
import React, { ComponentPropsWithoutRef, ReactNode } from 'react'
import classNames from 'classnames'
import Link from 'next/link'

export type ButtonElementType = 'a' | 'button' | typeof Link

type ButtonComponentProps<T extends ButtonElementType> = ComponentPropsWithoutRef<T> & {
    as: ButtonElementType
    isLoading?: boolean
    variant?: 'primary' | 'secondary' | 'tertiary' | 'black' | 'notSelected'
    children: ReactNode
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export type ButtonProps<T extends ButtonElementType> = ButtonComponentProps<T> &
    Omit<React.ComponentPropsWithRef<T>, keyof ButtonComponentProps<T>>

const Button = <T extends ButtonElementType>({
    as: Element,
    children,
    className,
    isLoading = false,
    variant,
    ...props
}: ButtonProps<T>) => {
    const btn =
        'flex items-center font-roboto uppercase tracking-[1.25px] font-medium [&>*]:shrink-0 [&>*]:mr-1.5 rounded leading-[16px] lg:leading-none text-sm px-[14px] py-[10px] lg:px-[22px] lg:py-[14px] border border-[1px] text-center transition block w-fit'
    const primary = `${btn} bg-weekly-red text-white hover:text-weekly-red border-transparent hover:bg-gray-100 hover:border-[1px] hover:border-weekly-red`
    const secondary = `${btn} bg-weekly-red text-white hover:text-weekly-red hover:bg-white border-transparent hover:border-weekly-red`
    const tertiary = `${btn} border-weekly-red text-weekly-red bg-gray-50 hover:bg-white`
    const black = `${btn} bg-black text-white rounded-full py-3`
    const notSelected = `${btn} text-black rounded-full border-black hover:bg-black hover:text-white`

    return (
        // @ts-ignore
        <Element
            className={classNames(btn, className, {
                [primary]: variant === 'primary',
                [secondary]: variant === 'secondary',
                [tertiary]: variant === 'tertiary',
                [black]: variant === 'black',
                [notSelected]: variant === 'notSelected',
            })}
            {...(props as ComponentPropsWithoutRef<T>)}
        >
            {isLoading ? <Spinner /> : children}
        </Element>
    )
}

export default Button
